import { Modal } from 'antd';
import { throttle, debounce } from 'lodash-es';
import { LOGIN_PATH, MENU_PATH } from '@utils/constants';
import { removeToken, removeUserName } from './auth';

function gotoLogin() {
    removeToken();
    removeUserName();

    window.location.href =
        window.location.origin + LOGIN_PATH + `?redirect=${encodeURIComponent(window.location.href)}`;
}

function copyToClipboard(text) {
    // 创建一个临时的textarea元素
    const textarea = document.createElement('textarea');
    textarea.style.position = 'absolute';
    textarea.style.bottom = '-9999px';
    textarea.style.left = '9999px';
    textarea.value = text;

    // 将textarea元素添加到DOM中
    document.body.appendChild(textarea);

    // 选择并复制textarea中的文本
    textarea.select();
    document.execCommand('copy');

    // 移除临时的textarea元素
    document.body.removeChild(textarea);
}

function parseObject(str) {
    let val = null;

    try {
        val = JSON.parse(str);
    } catch (e) {
        //
    }

    return val;
}

function openUrl(url, target = '_blank') {
    if (url) {
        const opener = window.open(url, target, 'noopener,noreferrer');

        if (opener) {
            opener.opener = null;
        }
    }
}

function getDomainByPath(url) {
    try {
        const parsedUrl = new URL(url);
        return parsedUrl.hostname || url;
    } catch (e) {
        return url;
    }
}

function setPageTitle(title) {
    if (title) {
        document.title = `AI智届 - ${title}`;
    } else {
        document.title = 'AI智届';
    }
}

const show800Message = debounce((message) => {
    Modal.confirm({
        title: '提示',
        content: message || '每日限额已经用尽，若需要继续使用，请登录！',
        okText: '立即登录',
        cancelText: '取消',
        onOk() {
            openUrl(MENU_PATH.login);
        }
    });
}, 1200);

export function sendPageView() {
    if (window.gtag) {
        window.gtag('event', 'page_view', {
            page_title: document.title,
            page_location: window.location.href,
            page_path: window.location.pathname + (window.location.hash || ''),
            send_to: 'G-KL51L2LY4X'
        });
    }
}

export { gotoLogin, copyToClipboard, parseObject, openUrl, getDomainByPath, setPageTitle, show800Message };
