// 登录地址
const LOGIN_PATH = '/iam/login';
const MENU_PATH = {
    login: 'https://console.tuoyunai.com/iam/login',
    register: 'https://console.tuoyunai.com/iam/login?type=register',
    aiSearch: 'https://console.tuoyunai.com/console/aitools#/search',
    aiApp: 'https://console.tuoyunai.com/console/aitools#/toolbox',
    aiChat: 'https://console.tuoyunai.com/console/aitools#/talk',
    aiDraw: 'https://console.tuoyunai.com/console/aitools#/draw',
    customerPipeline: 'https://console.tuoyunai.com/console/pipeline',

    costCenter: 'https://console.tuoyunai.com/console/iam/#/cost/center',
    resourceCenter: 'https://console.tuoyunai.com/console/iam/#/personalRes/center'
};

const LANG = {
    zhCN: { key: 'zh-CN', label: '中文' },
    enUS: { key: 'en-US', label: 'En' }
};

const PROMPT_LIST_PAGE_SIZE = 12;

export { LANG, LOGIN_PATH, MENU_PATH, PROMPT_LIST_PAGE_SIZE };
