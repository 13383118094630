export default {
    TITLE_HEADER_DOCUMENT_CENTER: 'Document Center',
    TITLE_HEADER_INRELIIGENT_CUSTOMER_SERVICE: 'Intelligent Customer Service',
    TITLE_HEADER_SHARE: 'Share',
    TITLE_HEADER_PERSONAL_CONTER: 'Personal Center',
    TITLE_HEADER_COST_CONTER: 'Cost Center',
    TITLE_HEADER_RESOURCE_CONTER: 'Resource Center',
    TITLE_HEADER_OPERATION_CONTER: 'Operation Center',
    TITLE_HEADER_LOG_OUT: 'Log Out',
    TITLE_HEADER_LOG_IN: 'Login',

    TIPS_HEADER_SHARE_COPY_SUCCESS: 'Successfully copied the sharing link.'
};
