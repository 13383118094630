import { useState, useEffect } from 'react';
import { HashRouter } from 'react-router-dom';
import { ConfigProvider, message } from 'antd';
import intl from 'react-intl-universal';
import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US';
import moment from 'moment';
import { StyleProvider, px2remTransformer } from '@ant-design/cssinjs';

import { LANG } from '@utils/constants';
import Routers from '@components/Routers';
import Header from '@components/Header';
import locales from '@/locales';
import useMobile, { useMobile776 } from '@/hooks/useMobile';
import routeCfg from './routeCfg';

import './index.less';

const MOMENT_LANGS = {
    'zh-CN': 'zh-CN',
    'en-US': 'en'
};

const ANTD_LANGS = {
    'zh-CN': zhCN,
    'en-US': enUS
};

message.config({
    top: 100
});

function App() {
    const [lang, setLang] = useState(LANG.zhCN.key);
    const [initCompleted, setInitCompleted] = useState(null);
    const isMobile = useMobile();
    const isMobile776 = useMobile776();

    function initLang() {
        let language = lang;

        if (![LANG.zhCN.key, LANG.enUS.key].includes(lang)) {
            language = LANG.zhCN.key;
        }

        loadLocales(language);
    }

    function loadLocales(language, callbackFn) {
        moment.locale(MOMENT_LANGS[lang]);
        intl.init({
            currentLocale: language,
            locales
        }).then(() => {
            setInitCompleted(new Date());

            if (callbackFn) {
                callbackFn();
            }
        });
    }

    useEffect(() => {
        initLang();
    }, []);

    const px2rem = px2remTransformer({
        rootValue: 14 // 14px = 1rem; @default 16
    });

    return (
        <ConfigProvider
            locale={ANTD_LANGS[lang]}
            theme={{
                token: {
                    colorPrimary: '#18b3b3'
                },
                components: {
                    Menu: {
                        horizontalLineHeight: '54px',
                        groupTitleFontSize: 16
                    }
                }
            }}
        >
            {initCompleted && (
                <HashRouter>
                    <StyleProvider transformers={[px2rem]} hashPriority="high">
                        <div className={`main-app ${isMobile ? 'mobile' : ''} ${isMobile776 ? 'mobile-766' : ''}`}>
                            <div className="background-content"></div>
                            <Header />

                            <div className="main-app-main">
                                <Routers routes={routeCfg} />
                            </div>
                        </div>
                    </StyleProvider>
                </HashRouter>
            )}
        </ConfigProvider>
    );
}

export default App;
