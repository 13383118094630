import { useState, useEffect, useLayoutEffect } from 'react';

const matchMedia = '(max-width: 767px)';

// 屏幕宽度小于767px的都是移动端
function useMobile776() {
    const isSsr = typeof window === 'undefined';
    const [matches, setMatches] = useState(() => (isSsr ? false : window.matchMedia(matchMedia).matches));

    useLayoutEffect(() => {
        if (isSsr) {
            return;
        }

        const mediaQueryList = window.matchMedia(matchMedia);
        const listener = (e: any) => setMatches(e.matches);
        mediaQueryList.addListener(listener);

        return () => mediaQueryList.removeListener(listener);
    }, [matchMedia]);

    return Boolean(matches);
}

function useMobile() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        let flag = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|SymbianOS)/i
        );
        setIsMobile(Boolean(flag));
    }, []);

    return isMobile;
}

export { useMobile776 };
export default useMobile;
