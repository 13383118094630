import { lazy, Suspense } from 'react';
import i18n, { LANG_KAY } from '@utils/i18n';

function lazyFunc(component) {
    const Comp = component;

    return (
        <Suspense fallback={<>{i18n.get(LANG_KAY.COMMON_TITLE_LOADING)}</>}>
            <Comp />
        </Suspense>
    );
}

export default [
    {
        key: 'index',
        name: 'index',
        path: '/',
        element: lazyFunc(lazy(() => import('../Routers/RouterBeforeEach'))),
        children: [
            {
                key: 'default',
                name: 'default',
                path: '/',
                element: lazyFunc(lazy(() => import('../Home')))
            },
            {
                name: 'prompts',
                path: '/prompts',
                element: lazyFunc(lazy(() => import('../Prompts')))
            },
            {
                key: 'tools',
                name: 'tools',
                path: '/tools',
                element: lazyFunc(lazy(() => import('../OverView')))
            },
            {
                key: 'doingPage',
                name: 'doingPage',
                path: '/doingPage',
                element: lazyFunc(lazy(() => import('../DoingPage')))
            }
        ]
    }
];
