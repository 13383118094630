import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '@components/App';
import '@utils/setFontSize';

import '@/styles/global.less';
import '@/styles/markdown.less';
import '@/styles/highlight.less';

function render() {
    const rootNode = ReactDOM.createRoot(document.getElementById('root_search'));
    rootNode.render(<App />);
}

render();
