import { useState } from 'react';
import { Button, Tooltip, message, Dropdown, Avatar, Card, Menu } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import i18n, { LANG_KAY } from '@utils/i18n';
import { copyToClipboard, openUrl } from '@utils/util';
import { MENU_PATH } from '@utils/constants';
import { ReactComponent as ShareSvg } from '@/assets/images/share.svg';
import { ReactComponent as AppStore } from '@/assets/images/appStore.svg';

import './index.less';

const { Meta } = Card;

const appList = [
    {
        key: 'AISearch',
        title: 'AI搜索',
        description: 'AI搜索',
        image: '/images/AIRobot.png',
        url: MENU_PATH.aiSearch
    },
    {
        key: 'AIApp',
        title: 'AI应用',
        description: 'AI应用',
        image: '/images/AIRobot.png',
        url: MENU_PATH.aiApp
    },
    {
        key: 'AIChat',
        title: 'AI对话',
        description: 'AI对话',
        image: '/images/AIRobot.png',
        url: MENU_PATH.aiChat
    },
    {
        key: 'AIImage',
        title: 'AI图像',
        description: 'AI图像',
        image: '/images/AIRobot.png',
        url: MENU_PATH.aiDraw
    }
    // {
    //     key: 'customerAcquisitionPipeline',
    //     title: '获客流水线',
    //     description: '获客流水线',
    //     image: '',
    //     url: MENU_PATH.customerPipeline
    // },
    // {
    //     key: 'customsData',
    //     title: '海关数据',
    //     description: '海关数据',
    //     image: '',
    //     url: ''
    // }
];

const menus = [
    {
        label: 'AI搜索',
        key: 'search',
        path: '/'
    },
    {
        label: 'AI工具集',
        key: 'tools',
        path: '/tools'
    },
    {
        label: 'AI提示词',
        key: 'prompt',
        path: '/prompts'
    },
    {
        label: 'AI制品',
        key: 'products',
        path: '/doingPage'
    }
];

export default () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedKeys, setselectedKeys] = useState([getDefaultMenuKey()]);

    function getDefaultMenuKey() {
        const target = menus.find((item) => item.path === location.pathname);

        return target?.key || 'search';
    }

    function onClickShare() {
        try {
            copyToClipboard(window.location.href);
            message.success(i18n.get(LANG_KAY.TIPS_HEADER_SHARE_COPY_SUCCESS));
        } catch (e) {
            //
        }
    }

    function onClickLogin() {
        openUrl(MENU_PATH.login);
    }

    function onClickApp(data) {
        openUrl(data.url);
    }

    function onClickMenu({ item, key }) {
        navigate(item?.props?.path);
        setselectedKeys([key]);
    }

    function dropdownRender() {
        return (
            <div className="app-store-container">
                {appList.map((item) => {
                    const { key, title, image, description } = item;

                    return (
                        <Meta
                            className="app-item"
                            key={key}
                            avatar={<Avatar shape="square" size={64} src={image} />}
                            title={title}
                            // description={description}
                            onClick={() => onClickApp(item)}
                        />
                    );
                })}
            </div>
        );
    }

    return (
        <header className="page-header">
            <div className="left">
                <img src="/images/logoNew.svg"></img>
                <Link className="system-name" to="/">
                    智届
                </Link>
            </div>
            <div className="center">
                <Menu
                    className="center-menu"
                    mode="horizontal"
                    items={menus}
                    selectedKeys={selectedKeys}
                    onClick={onClickMenu}
                />
            </div>

            <div className="right">
                <Button type="primary" shape="round" onClick={onClickLogin}>
                    {i18n.get(LANG_KAY.TITLE_HEADER_LOG_IN)}
                </Button>

                {/* <div className="item">{i18n.get(LANG_KAY.TITLE_HEADER_LOG_IN)}</div> */}

                <Tooltip title={i18n.get(LANG_KAY.TITLE_HEADER_SHARE)} color="rgba(0,0,0, 0.6)">
                    <ShareSvg className="item" onClick={onClickShare} />
                </Tooltip>

                <Dropdown trigger="click" dropdownRender={dropdownRender}>
                    <AppStore className="item" />
                </Dropdown>
            </div>
        </header>
    );
};
